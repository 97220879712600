import { memo } from 'react';
import { createUseStyles } from 'react-jss';

const PrismLightTheme = () => {
  useStyles();
  return null;
};

const useStyles = createUseStyles({
  '@global': {
    '.assistant-answer code[class*="language-"], .assistant-answer pre[class*="language-"], .assistant-answer pre': {
      '&': {
        color: 'black',
        background: 'none',
        textShadow: '0 1px white',
        fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
        fontSize: '1em',
        textAlign: 'left',
        whiteSpace: 'pre',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        wordWrap: 'normal',
        lineHeight: '1.5',
        mozTabSize: '4',
        oTabSize: '4',
        tabSize: '4',
        webkitHyphens: 'none',
        mozHyphens: 'none',
        msHyphens: 'none',
        hyphens: 'none',
      },
      '& .token': {
        '&.comment': {
          color: 'slategray',
        },
        '&.punctuation': {
          color: '#999',
        },
        '&.namespace': {
          opacity: '.7',
        },
        '&.property': {
          color: '#905',
        },
        '&.tag': {
          color: '#905',
        },
        '&.boolean': {
          color: '#905',
        },
        '&.number': {
          color: '#905',
        },
        '&.constant': {
          color: '#905',
        },
        '&.symbol': {
          color: '#905',
        },
        '&.deleted': {
          color: '#905',
        },
        '&.selector': {
          color: '#690',
        },
        '&.attr-name': {
          color: '#690',
        },
        '&.string': {
          color: '#690',
        },
        '&.char': {
          color: '#690',
        },
        '&.builtin': {
          color: '#690',
        },
        '&.inserted': {
          color: '#690',
        },
        '&.operator': {
          color: '#9a6e3a',
        },
        '&.entity': {
          color: '#9a6e3a',
          background: 'hsla(0, 0%, 100%, .5)',
          cursor: 'help',
        },
        '&.url': {
          color: '#9a6e3a',
          background: 'hsla(0, 0%, 100%, .5)',
        },
        '.language-css .token.string, .style .token.string': {
          color: '#9a6e3a',
          background: 'hsla(0, 0%, 100%, .5)',
        },
        '&.atrule': {
          color: '#07a',
        },
        '&.attr-value': {
          color: '#07a',
        },
        '&.keyword': {
          color: '#07a',
        },
        '&.function': {
          color: '#DD4A68',
        },
        '&.class-name': {
          color: '#DD4A68',
        },
        '&.regex': {
          color: '#e90',
        },
        '&.important': {
          color: '#e90',
          fontWeight: 'bold',
        },
        '&.variable': {
          color: '#e90',
        },
        '&.bold': {
          fontWeight: 'bold',
        },
        '&.italic': {
          fontStyle: 'italic',
        },
      },
    },
    '@media print': {
      '.assistant-answer code[class*="language-"], .assistant-answer pre[class*="language-"], .assistant-answer pre': {
        textShadow: 'none',
      },
    },
    '.assistant-answer pre[class*="language-"], .assistant-answer pre': {
      padding: '8px 16px',
      margin: '0',
      overflow: 'auto',
    },
    '.assistant-answer :not(pre) > code[class*="language-"], .assistant-answer pre[class*="language-"], .assistant-answer pre': {
      background: '#f4f4f4',
      borderRadius: '8px',
      border: '1px solid var(--joy-palette-background-level2)',
    },
    '.assistant-answer :not(pre) > code[class*="language-"]': {
      padding: '8px 16px',
      borderRadius: '8px',
      whiteSpace: 'normal',
    },
  },
});

export default memo(PrismLightTheme);
