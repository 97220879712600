import { type FC, memo, useCallback } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import { type BoxProps, Box, IconButton, Tooltip } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';
import Icon from 'ui/Icon';

export interface ShowToggleProps extends BoxProps {
  isShowForce?: boolean;
  disabled?: boolean;
}

const ShowToggle: FC<ShowToggleProps> = (props) => {
  const { isShowForce, disabled, ...rest } = props;

  const dispatcher = useDispatcher();

  const isShow = useSelector((state: DefaultRootState) => isShowForce ?? copilotStore.selectors.rightBarIsShow(state));

  const handleCopilot = useCallback(() => {
    if (isShow) {
      dispatcher.copilot.close();
    } else {
      dispatcher.copilot.open();
    }
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('resize'));
    }, 10);
  }, [isShow]);

  return (
    <Box {...rest}>
      <Tooltip arrow variant="outlined" size="sm" title={isShow ? 'Close AI Assistant' : 'Open AI Assistant'} disableInteractive>
        <IconButton size="sm" color="neutral" variant="soft" disabled={disabled} onClick={handleCopilot}>
          <Icon name={isShow ? 'arrow-right-long-to-line' : 'arrow-left-long-to-line'} fw weight="light" color="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(ShowToggle);
