import { memo, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, IconButton, Tooltip, Typography, useColorScheme } from '@mui/joy';

import Icon from 'ui/Icon';
import { useNavigate } from 'navigation/hooks';
import { useFolderRoute } from 'screens/Space/model/folderRoute';
import usePermissionsGetter from 'screens/Space/model/usePermissionsGetter';
import useMyLibrary from 'screens/Space/model/useMyLibrary';

const AddNoteButton = () => {
  const navigate = useNavigate();
  const permissionsGetter = usePermissionsGetter();
  const { colorScheme } = useColorScheme();
  const { itemId } = useFolderRoute();
  const myLibrary = useMyLibrary();

  const handleClick = useCallback(() => {
    navigate('Note', {
      id: itemId && permissionsGetter.get(itemId)?.hasAction('WRITE') ? `${itemId}::create` : `${myLibrary.spaceId}::${myLibrary.entityId}::create`,
    });
  }, [navigate, itemId, permissionsGetter, myLibrary]);

  return (
    <Tooltip arrow variant="outlined" size="sm" title="Create a smart document with your library co-pilot" disableInteractive>
      <IconButton
        sx={{
          '--variant-softColor': colorScheme === 'light' ? 'var(--joy-palette-neutral-100)' : 'var(--joy-palette-neutral-900)',
          '--variant-softBg': colorScheme === 'light' ? 'var(--joy-palette-neutral-700)' : 'var(--joy-palette-common-white)',
          '--variant-softHoverBg': colorScheme === 'light' ? 'var(--joy-palette-neutral-600)' : 'var(--joy-palette-neutral-200)',
          '--variant-softActiveColor': colorScheme === 'light' ? 'var(--joy-palette-neutral-100)' : 'var(--joy-palette-neutral-900)',
          '--variant-softActiveBg': colorScheme === 'light' ? 'var(--joy-palette-neutral-500)' : 'var(--joy-palette-neutral-400)',
          '--joy-palette-text-primary': 'current',
        }}
        size="sm"
        color="neutral"
        variant="soft"
        onClick={handleClick}
      >
        {/* <Icon name="file-pen" size="1x" fw weight="solid" color="inherit" /> */}
        <Icon name="pen-to-square" size="1x" fw weight="regular" color="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default memo(AddNoteButton);
