import { type SagaReturnType, put, cancel, takeLatest, delay } from 'redux-saga/effects';
import * as uuid from 'uuid';

import network from 'lib/network';
import { call, select } from 'store/utils/saga/effects';
import { gtm } from 'utils';

import { track } from '@amplitude/analytics-browser';

import { folderRoute } from 'screens/Space/model/folderRoute';
import * as askStore from 'store/nodes/ask';
import * as spaceStore from 'screens/Space/store';

import { actions } from '../slice';
import * as selectors from '../selectors';
import type { ResourceType } from '../types';

export const config = {
  action: [actions.sendQuestion.type, actions.sendCommand.type],
  method: takeLatest,
};

function* getAskSpaceId() {
  const { spaceId } = yield* call(folderRoute);
  if (spaceId) {
    return spaceId;
  }
  const selectedSource = yield* select(askStore.selectors.selectedSource);
  if (selectedSource && selectedSource.id) {
    return selectedSource.id;
  }
  const defaultSpaceId = yield* select(spaceStore.selectors.defaultSpaceId);
  return defaultSpaceId;
}

function* getCopilot(requestId: string, type: ResourceType, resourceId: number | string | null, query: string) {
  if (type === 'material') {
    return { name: 'material', body: { request_id: requestId, query, content_id: resourceId } };
  }
  if (type === 'collection') {
    return { name: 'collection', body: { request_id: requestId, query, collection_id: resourceId } };
  }
  if (type === 'note') {
    return { name: 'note', body: { request_id: requestId, query, note_id: resourceId } };
  }
  if (type === 'library') {
    return { name: 'library', body: { request_id: requestId, query } };
  }
  if (type === 'web') {
    return { name: 'web/new', body: { request_id: requestId, query } };
  }
  if (type === 'research') {
    const spaceId = yield* getAskSpaceId();
    return { name: 'research', body: { request_id: requestId, query, team_space_id: spaceId } };
  }
  if (type === 'library-fulltext') {
    const spaceId = yield* getAskSpaceId();
    return { name: 'team-space', body: { request_id: requestId, query, team_space_id: Number(spaceId) } };
  }
  if (type === 'global-fulltext') {
    return { name: 'fulltext/new', body: { request_id: requestId, query } };
  }
  if (type === 'Space') {
    return { name: 'team-space', body: { request_id: requestId, query, team_space_id: Number(resourceId) } };
  }
  if (type === 'SpaceFolder' && typeof resourceId === 'string') {
    const [spaceId, folderId] = resourceId.split('::');
    return { name: 'folder', body: { request_id: requestId, query, team_space_id: Number(spaceId), folder_id: Number(folderId) } };
  }
  return null;
}

export function* func(action: SagaReturnType<typeof actions.sendQuestion | typeof actions.sendCommand>) {
  const { reloadId, resourceId = null, text, resourceType } = action.payload;

  if (!text) {
    yield cancel();
    return;
  }

  let requestId = uuid.v4();
  const selectedModel = yield* select(selectors.selectedModel);

  if (reloadId) {
    requestId = reloadId;
  } else {
    yield put(
      actions.addItem({
        requestId,
        resourceId,
        resourceType,
        label: action.type === actions.sendCommand.type ? action.payload.label || null : null,
        text,
        type: 'question',
      }),
    );
    yield delay(10);
  }
  yield put(
    actions.answerStart({
      requestId,
      resourceId,
      resourceType,
      modelId: selectedModel?.id || 0,
    }),
  );

  const copilot = yield* getCopilot(requestId, resourceType, resourceId, text);
  if (!copilot) {
    yield put(actions.answerAddChunk({ requestId, text: 'Unknown assistant params' }));
    yield delay(10);
    yield put(actions.answerEnd({ requestId }));
    yield cancel();
    return;
  }

  const { errors, data } = yield* call(() =>
    network
      .request<any>(`copilot/chat/${copilot.name}`, {
        baseUrl: process.env.COPILOT_DOMAIN,
      })
      .body(copilot.body)
      .post(),
  );

  const [error] = errors || [];
  if (error && error.message === 'Please register to continue chatting') {
    yield put(actions.answerError({ requestId, type: 'unauthorized' }));
    yield cancel();
    return;
  }

  if (resourceType === 'material') {
    yield* call(() =>
      track('Ask', {
        from: 'content',
        filter: 'content',
      }),
    );
  }
  if (resourceType === 'library-fulltext') {
    yield* call(() =>
      track('Ask', {
        from: 'library',
        filter: 'library',
      }),
    );
  }
  if (['web', 'global-fulltext', 'research'].includes(resourceType)) {
    const filterSearchEventMap = {
      web: 'web',
      research: 'research',
      'global-fulltext': 'iki',
    };
    const filterSearch = filterSearchEventMap[resourceType as 'web' | 'research' | 'global-fulltext'];
    yield* call(() =>
      track('Ask', {
        from: 'ask',
        filter: filterSearch,
      }),
    );
  }

  if (['web', 'library-fulltext', 'global-fulltext'].includes(resourceType)) {
    yield call(() =>
      gtm.dataLayer.push({
        event: 'ask',
        ask_type: copilot.name,
      }),
    );
  }

  if (Array.isArray(errors) && errors.length > 0) {
    yield put(
      actions.answerAddChunk({
        requestId,
        text: errors[0]?.message || 'Unknown error',
      }),
    );
    yield delay(10);
    yield put(actions.answerEnd({ requestId }));
  }
}
