import { memo, forwardRef, useImperativeHandle } from 'react';
import { useColorScheme } from '@mui/joy';
import PrismJS from 'prismjs';

import PrismLightTheme from './themes/PrismLightTheme';
import PrismDarkTheme from './themes/PrismDarkTheme';

const Prism = forwardRef<{ highlightAll: typeof PrismJS.highlightAll }, unknown>((props, forwardedRef) => {
  const { mode } = useColorScheme();

  useImperativeHandle(
    forwardedRef,
    () => ({
      highlightAll: PrismJS.highlightAll,
    }),
    [],
  );

  return (
    <>
      {mode === 'light' && <PrismLightTheme />}
      {mode === 'dark' && <PrismDarkTheme />}
    </>
  );
});

export default memo(Prism);
