import { usePerson } from 'hooks';

const useCurrentPromo = () => {
  const { person } = usePerson('User', 'my');

  if (!person || !person.createdAt) {
    return {
      hasPromo: false,
    };
  }

  return {
    hasPromo: false, // disabled
    description: '1 free month promo code',
    code: 'PH25',
  };
};

export default useCurrentPromo;
